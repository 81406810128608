import React, {useEffect, useRef, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Image from "../UI/Image";
import {carouselAnimation, logoLeaveAnimation, logoStoppedAnimation} from "../../animations/homePage";
import '../../animations/homePage/homeAnimations.scss';
import Link from "../Link";

const createArrayRefs = ( items) => {
    let arrayRefs = [];
    for(let i = 0; i <= items; ++i){
        arrayRefs.push(React.createRef())
    }
    return arrayRefs;
}

const InfiniteAutoCarousel = ({list = [], reverse = false}) => {
    const {width} = useWindowSize();
    const [separation, setSeparation] = useState(null);
    const [distance, setDistance] = useState(null)
    const [gsapTimeline, setGsapTimeline] = useState(null)
    let refs = useRef([...createArrayRefs(list.length)]);

    useEffect(() => {
        if(gsapTimeline){
            gsapTimeline?.play()
        }
        setCardSeparation();
        setGsapTimeline(carouselAnimation(separation,distance,list,reverse,refs))
        return () => {
            gsapTimeline?.pause()
        }
    }, [separation]);

    // method to set the card separation
    const setCardSeparation = () => {
        const amount = list.length;
        // separation calculation
        const separationCalc = Math.round(width / (amount - 1));
        // the real calculation depending on the result
        const separation = separationCalc > 180 ? separationCalc : 180;
        // updates
        setSeparation(separation);
        setDistance((separation * amount))
    }

    const onLogoMouseEnter = (ref) => {
        logoStoppedAnimation(ref)
    }

    const onLogoMouseLeave = (ref) => {
        logoLeaveAnimation(ref)
    }

    const renderCard = (item, index) => {
        return <div className="carousel-item" ref={refs.current[index]} onMouseOver={() => onLogoMouseEnter(refs.current[index])} onMouseLeave={() => onLogoMouseLeave(refs.current[index])} key={index}>
            <Image className="clients-logo" filename={item.logo} key={index} alt={"client"}/>
        </div>
    }

    return <div className="carousel">
        {list.map((item, index) => item.link ? <Link to={item.link} style={{textDecoration: 'none'}} key={index}>{renderCard(item, index)}</Link> : renderCard(item, index))}
    </div>
};

export default InfiniteAutoCarousel;
