import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

const Container = styled.div`
height:${(props)=> props.height};
width:${(props)=> props.width};
margin: ${(props)=> props.margin ? `${props.margin};` : props.centered ? '0 auto;' : 'inherit;'}
overflow:hidden;
`

const LottieController = ({ lottieJSON, inView ,height,width,loop= false, centered = true, margin}) => {
    const container = useRef(null);

    useEffect(() => {
        if (inView) {
            animationTimeOut()
        }
    }, [inView])

    const animationTimeOut = () => setTimeout(() => loadLottieAnimation(), 500);

    const loadLottieAnimation = () => {
        return lottie.loadAnimation({
            container: container.current,
            rendered: 'canvas',
            loop: loop,
            autoplay:true,
            animationData: lottieJSON,
        })
    }

    return <Container ref={container} height={height} width={width} centered={centered} margin={margin}/>
}

export default LottieController;
