import React from 'react';
import {Heading, Paragraph} from "../../UI/Typography";
import Link from "../../Link";
import {Button} from "../../UI/Button";
import {FormattedMessage} from "react-intl";

const PanelDescription = ({titleId, descId, link, intl}) => {

    return <div className="panel-text blue-text">
        <Heading level={"3"} bold={true} margin={0}>
            <FormattedMessage id={titleId}/>
        </Heading>
        <Paragraph className="case-description">
            <FormattedMessage id={descId} values={{newline: <br/>}}/>
        </Paragraph>
        {link && <Link to={link} className="link-no-decoration">
            <Button arrow label={intl.formatMessage({id: 'home.case_cta'})} nopadding={true}/>
        </Link>}
    </div>

}


export default PanelDescription;
