import React, {useCallback, useEffect, useRef, useState,} from 'react';
import "./index.scss"
import {FormattedMessage} from "react-intl";
import {Heading, Paragraph} from "../UI/Typography";
import {scrollerDarkInfosAnimations} from "../../animations/homePage";
import useWindowSize from "../../hooks/useWindowSize";
import Dash from "../UI/Dash";
import Link from "../Link";
import {Button} from "../UI/Button";
import Fade from "react-reveal/Fade.js";
import LottieController from "../UI/LottieController";
import Multifunction from "../UI/Lottie/multifunzionali.json";
import NativeDig from "../UI/Lottie/nativi digitali.json";
import Network from "../UI/Lottie/network aziendali.json";
import {useInView} from "react-intersection-observer";

const ScrollDarkInfos = ({intl}) => {
    const {width} = useWindowSize();
    const isMobile = width < 768;
    const trigger = useRef(null);
    const section = useRef(null);
    const [isDark, setIsDark] = useState(false);
    const [iconNativeDig, inViewNativeDig] = useInView();
    const [iconNetwork, inViewNetwork] = useInView();
    const [iconMultifunction, inViewMultifunction] = useInView();

    const setIsDarkCb = useCallback((dark) => setIsDark(dark), []);

    useEffect(() => {
        if (!isMobile) scrollerDarkInfosAnimations(trigger, section, setIsDarkCb);
    }, []);

    return <div ref={section}>
        <div className="section-padding">
            <div className="container">
                <div className="row my-4 align-items-start justify-content-between">
                    <div className="col-md-5 col-12" ref={trigger}>
                        <label className={"special-label"}>
                            <FormattedMessage id="home.method_eyelet"/>
                        </label>
                        <Heading level={'2'} thinChild={1} margin={'0px 0px 24px'} className={"custom-color"}>
                            <FormattedMessage id="home.method_title_thin"/>
                            <FormattedMessage id="home.method_title"/>
                        </Heading>
                        <Dash margin={'0 0 48px'}/>
                        <div className={'d-none d-md-block'}>
                            <Link to={"/services/"} className="link-no-decoration">
                                <Button type={isDark ? 'whiteTransparent' : ''} arrow label={intl.formatMessage({id: 'home.method_cta'})} nopadding={true}/>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <WhyUsParagraph text={'home.method_text_1'} isFirst={true} isDark={isDark} intl={intl}/>
                        <div ref={iconNativeDig}>
                            <WhyUsParagraph title={'home.why_us_1_title'} text={'home.why_us_1_desc'}
                                            lottieJSON={NativeDig} inView={inViewNativeDig} intl={intl}/>
                        </div>
                        <div ref={iconNetwork}>
                            <WhyUsParagraph title={'home.why_us_2_title'} text={'home.why_us_2_desc'}
                                            lottieJSON={Network} inView={inViewNetwork} intl={intl}/>
                        </div>
                        <div ref={iconMultifunction}>
                            <WhyUsParagraph title={'home.why_us_3_title'} text={'home.why_us_3_desc'} endTrigger={true}
                                            lottieJSON={Multifunction} inView={inViewMultifunction} intl={intl}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ScrollDarkInfos;

const WhyUsParagraph = ({title, text, isFirst = false, endTrigger = false, lottieJSON, inView, isDark, intl}) => {
    return <Fade bottom>
        <div className="pros">
            {lottieJSON && <LottieController lottieJSON={lottieJSON} inView={inView} height={'76px'} width={'76px'}
                               loop={false} centered={false} margin={'0 0 24px'}/>}
            {title && <label className={`special-label ${endTrigger && 'end-scroll-title'}`}>
                <FormattedMessage id={title}/>
            </label>}
            <Paragraph className={"custom-color"} marginTop={isFirst ? '0' : ''}>
                <FormattedMessage id={text}/>
            </Paragraph>
            {isFirst && <div className={'col-12 d-block d-md-none p-0'}>
                <Link to={"/services/"} className="link-no-decoration">
                    <Button type={isDark ? 'whiteTransparent' : ''} arrow label={intl.formatMessage({id: 'home.method_cta'})} nopadding={true}/>
                </Link>
            </div>}
        </div>
    </Fade>
};
