import React, {useEffect, useRef,} from 'react';
import "./index.scss"
import Panel from "./Panel/Panel";
import PanelDescription from "./Panel/PanelDescription";
import CaseList from "../CaseList";
import useWindowSize from "../../hooks/useWindowSize";
import {scrollerSectionsAnimations} from "../../animations/homePage";

const ScrollSections = ({intl, list, topDescription = false}) => {
    const {width} = useWindowSize();
    const isMobile = width < 992;
    const trigger  = useRef(null);

    useEffect(() => {
        if(isMobile) return;
        scrollerSectionsAnimations(trigger, list[list.length - 1]?.name, topDescription);
    }, []);

    return <>
        <CaseList list={list} hasFilters={false} hasCta={true} intl={intl} classname={"mt-5 d-block d-lg-none"}/>
        <div className="container d-none d-lg-block">
            <div className="scroller">
                <section className="medias" ref={trigger}>
                    <div className="p-wrap">
                        {list.map((listItem, index) => <Panel name={listItem?.name} poster={listItem?.poster}
                                                              video={listItem?.video} link={listItem?.to} key={index}/>)}
                    </div>
                    <div className="text-wrap">
                        {list.map((listItem, index) => <PanelDescription descId={listItem?.subtitle} titleId={listItem?.title}
                                                                         link={listItem?.to} key={index} intl={intl}/>)}
                    </div>
                </section>
            </div>
        </div>
    </>
};


export default ScrollSections;
