import React from "react";
import {injectIntl} from "react-intl";
import withLayout from "../layout";
import HomeHero from "../components/HomeHero";
import SEO from "../components/SEO";
import FullWidthMessage from "../components/FullWidthMessage";
import ScrollSections from "../components/ScrollerSections";
import ScrollDarkInfos from "../components/ScrollerDarkInfos";
//Videos
import acea from '../images/portfolio/Acea.mp4';
import maui from '../images/portfolio/Mauijim.mp4';
import audi from '../images/portfolio/Audi.mp4';
import translated from '../images/portfolio/Translated.mp4';
import jago from '../images/portfolio/Jago.mp4';
//Thumbs
import AceaThumb from '../images/portfolio/ACEA_thumbnail.webp';
import audiThumb from '../images/portfolio/AUDI_thumbnail.webp';
import mauiThumb from '../images/portfolio/MAUI-JIM_thumbnail.webp';
import translatedThumb from '../images/portfolio/TRANSLATED_thumbnail.webp';
import jagoThumb from '../images/portfolio/Jago.webp';
import Link from "../components/Link";
import {Button} from "../components/UI/Button";

const CLIENTS = [
    {
        name: 'jago',
        video: jago,
        title: 'portfolio.jago.title',
        poster: jagoThumb,
        subtitle: 'portfolio.jago.description',
        to: '/case-study/jago',
        types: ['design_dev', 'creative_communication']
    },
    {
        name: 'translated',
        video: translated,
        title: 'portfolio.translated.title',
        poster: translatedThumb,
        subtitle: 'portfolio.translated.description',
        to: '/case-study/translated',
        types: ['data_analysis_consulting','design_dev']
    },
    {
        name: 'audi',
        video: audi,
        title: 'portfolio.audi.title',
        poster: audiThumb,
        subtitle: 'portfolio.audi.description',
        to: '/case-study/audi',
        types: ['data_analysis_consulting']
    },
    {
        name: 'maui',
        video: maui,
        title: 'portfolio.maui.title',
        poster: mauiThumb,
        subtitle: 'portfolio.maui.description',
        to: '/case-study/maui',
        types: ['data_analysis_consulting','design_dev']
    },
    {
        name: 'acea',
        video: acea,
        title: 'portfolio.acea.title',
        subtitle: 'portfolio.acea.description',
        poster: AceaThumb,
        to: '/case-study/acea',
        types: ['design_dev']
    },
];

const IndexPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'home.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'home.meta_title'})}
             description={intl.formatMessage({id: 'home.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <HomeHero title={"home.hero_title"} titleThin={"home.hero_title_thin"} subtitle={"home.hero_subtitle"}/>
        <ScrollDarkInfos intl={intl}/>
        <ScrollSections intl={intl} list={CLIENTS}/>
        <div className="d-none d-lg-flex justify-content-center align-items-center" style={{position: 'relative', background: '#fff', height: 176, width: '100%'}}>
            <div style={{width: 'max-content'}}>
                <Link className="link-no-decoration" to={"/portfolio/"}>
                    <Button type={'primary'} arrow label={intl.formatMessage({id: 'home.all_success_cases'})}/>
                </Link>
            </div>
        </div>
        <FullWidthMessage title={"home.tell_vision_title"} text1={"home.tell_vision_text_1"}
                          text2={'home.tell_vision_text_2'}
                          ctaLink={"/contact-us/"} ctaText={"home.hero_main_cta"} blur={true} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "home", // same as file name in src/i18n/translations/your-lang/index.js
};

export default (withLayout(customProps))(injectIntl(IndexPage));
