import React from 'react';
import Link from "../../Link";

const Panel = ({poster,video,name,link}) => {

    return  <div className={'cursor-view'}>
        <Link to={link} className="link-no-decoration">
            <div className={`panel ${name}`}>
                <div className="media-video" dangerouslySetInnerHTML={{
                    __html: `<video style="height:100%" loop muted autoplay playsinline
                 webkit-playsinline poster=${poster} src="${video}"/>`
                }}/>
            </div>
        </Link>
    </div>
};


export default Panel;
