import React, {useEffect, useRef} from 'react';
import {ThemeProvider} from "styled-components";
import Header from '../Header';
import DropBg from '../UI/DropBg';
import DropBig from '../../images/bkg_drop_header.svg';
import {Heading, Paragraph} from '../UI/Typography'
import {FormattedMessage} from "react-intl";
import dark from "../../theme/dark";
import Fade from "react-reveal/Fade.js";
import SmallDropBlue from "../../images/SmallDropBlue.svg";
import InfiniteAutoCarousel from "../Carousels/InfiniteAutoCarousel";
import {dropCursorMirror} from "../../animations/homePage";

const carouselOne = [
    {logo: 'home/Acea.png', link: '/case-study/acea'},
    {logo: 'home/Arken.png'},
    {logo: 'home/Audi.png', link: '/case-study/audi'},
    {logo: 'home/Bmw.png'},
    {logo: 'home/Carinci.png'},
    {logo: 'home/Enel.png', link: '/case-study/enel'},
    {logo: 'home/Fiorentina.png', link: '/case-study/fiorentina'},
    {logo: 'home/Izzo.png'},
    {logo: 'home/Jago.png', link: '/case-study/jago'},
    {logo: 'home/JTI.png', link: '/case-study/jti'}
];

const carouselTwo = [
    {logo: 'home/Klopman.png'},
    {logo: 'home/L_automobile.png'},
    {logo: 'home/Lazioinnova.png'},
    {logo: 'home/Mantegna.png', link: '/case-study/mantegna'},
    {logo: 'home/Matecat.png'},
    {logo: 'home/Maui.png', link: '/case-study/maui'},
    {logo: 'home/Mercedes.png'},
    {logo: 'home/Pi.png'},
    {logo: 'home/Translated.png', link: '/case-study/translated'}
]

const Hero = ({title, titleThin, subtitle}) => {
    const dropRef = useRef(null);

    useEffect(() => {
        window?.addEventListener('mousemove', playDropAnimation);

        return () => {
            window?.removeEventListener('mousemove', playDropAnimation);
        }
    }, []);

    const playDropAnimation = (e) => {
        const dropAnimation = dropCursorMirror(e, dropRef);
        dropAnimation.play();
    };

    return <ThemeProvider theme={dark}>
        <div className="HomeHero">
            <Header/>
            <div className="container">
                <div className="title row d-flex justify-content-center align-items-center content position-relative">
                    <DropBg src={DropBig} top={"200px"} left={"-100px"} width={"426px"} height={"412px"} blur={true}/>
                    <DropBg src={SmallDropBlue} left={"15px"} top={'280px'} hideOnMobile={true} ref={dropRef}/>
                    <div className="col-12 text-center mt-5 mt-md-0">
                        <Fade bottom delay={500}>
                            <Heading level={"0"} thinChild={3} lineHeight={1}>
                                <FormattedMessage id={title}/><br/>
                                <FormattedMessage id={titleThin}/><span style={{color: "#B84FF8"}}>.</span>
                            </Heading>
                        </Fade>
                        <Fade bottom delay={800}>
                            <Paragraph customColor={"violetLighten100"} bold={true} fontStyleBold={true} margin={"40px 0 0 0"} customSize={16}>
                                <FormattedMessage id={subtitle}/>
                            </Paragraph>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="carousel-wrapper">
                <InfiniteAutoCarousel list={carouselOne}/>
                <InfiniteAutoCarousel list={carouselTwo} reverse={true}/>
            </div>
        </div>
    </ThemeProvider>
}


export default Hero;
